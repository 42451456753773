import { CartItemInput } from '../../../engine/apis/magento2/types.generated.d';
import { ICartContextInternalAPI } from '../CartContext';
import { invalidateCartCheckoutDetails } from './apolloCacheUtils';
import { useAddProductsToCartMutation } from '../graphql';
import { useCallback } from 'react';
import { useCartContext } from '../useCartContext';

export const useAddProductsToCartAction = () => {
    const [addProductsToCartMutation, addProductsToCartMutationResult] =
        useAddProductsToCartMutation();

    const { getCartId } = useCartContext() as ICartContextInternalAPI;

    const addProductsToCart = useCallback(
        async (products: Array<CartItemInput>) => {
            const cartId = await getCartId();

            if (!cartId) {
                console.error('useAddProductToCartAction: No cartId found');
                return null;
            }

            const resp = await addProductsToCartMutation({
                variables: {
                    cartId,
                    products: products,
                },
                update: invalidateCartCheckoutDetails,
            });

            return resp;
        },
        [addProductsToCartMutation, getCartId]
    );

    return [addProductsToCart, addProductsToCartMutationResult] as const;
};
