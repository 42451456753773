module.exports = [{
      plugin: require('../../../node_modules/@sbi-international/boutik.package.gatsby.plugin.inserts/dist/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/@sbi-international/boutik.gatsby.plugin.account/dist/gatsby-browser.js'),
      options: {"plugins":[],"currentWebsite":"mfp"},
    },{
      plugin: require('../../gatsby-theme-boutik-ecommerce/gatsby-browser.js'),
      options: {"plugins":[],"magentoBackend":"https://magento-production.sbintl.net","siteName":"mfp","configPath":"/home/runner/work/boutik.mfp/boutik.mfp/packages/site-mfp/src/i18n/config.json","injectSiteManifestDefinition":"auto","injectManifestPluginDefinition":"auto"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"2222881444455676"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png","background_color":"#FFFFFF","theme_color":"#ffffff","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"lang":"fr-CA","start_url":"/ca/fr","name":"name","short_name":"short_name","description":"description","localize":[{"lang":"en-CA","start_url":"/ca/en","name":"name","short_name":"short_name","description":"description"},{"lang":"en-US","start_url":"/us/en","name":"name","short_name":"short_name","description":"description"}],"cacheDigest":"ecb5de342dc3c8266caa4d392fa3da89"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
