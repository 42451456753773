import { ICartContextInternalAPI } from '../CartContext';
import { useCartContext } from '../useCartContext';
import { useSetShippingMethodsOnCartMutation } from '../graphql';

export const useSetShippingMethodsOnCartAction = () => {
    const [
        setShippingMethodsOnCartMutation,
        setShippingMethodsOnCartMutationResult,
    ] = useSetShippingMethodsOnCartMutation();

    const { getCartId } = useCartContext() as ICartContextInternalAPI;

    const setShippingMethod = async (
        carrier_code: string,
        method_code: string
    ) => {
        const cartId = await getCartId();
        if (cartId) {
            const resp = await setShippingMethodsOnCartMutation({
                variables: {
                    cart_id: cartId,
                    carrier_code: carrier_code,
                    method_code: method_code,
                },
            });

            return resp;
        } else {
            return Promise.resolve(undefined);
        }
    };

    const setShippingMethodByCartId = async (
        carrier_code: string,
        method_code: string,
        cart_id: string
    ) => {
        const resp = await setShippingMethodsOnCartMutation({
            variables: {
                cart_id: cart_id,
                carrier_code: carrier_code,
                method_code: method_code,
            },
        });

        return resp;
    };

    return {
        setShippingMethod,
        setShippingMethodByCartId,
        setShippingMethodsOnCartMutationResult,
    } as const;
};
