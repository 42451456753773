import { useGetBundleProductsQuery } from '../graphql';

export function useGetBundleProductsAction({
    sku,
}: {
    sku: string | undefined | null;
}) {
    let bundleSku;
    const bundleKeyword = '-Bundle';

    if (sku?.includes(bundleKeyword)) {
        bundleSku = sku;
    } else {
        bundleSku = sku + bundleKeyword;
    }

    const { data, loading, error } = useGetBundleProductsQuery({
        variables: {
            sku: bundleSku,
        },
    });

    return [data] as const;
}
