export function normalizeToLink(str: string | undefined | null): string {
    const accentsMap: { [key: string]: string } = {
        à: 'a',
        á: 'a',
        â: 'a',
        ä: 'a',
        ã: 'a',
        å: 'a',
        æ: 'ae',
        ç: 'c',
        è: 'e',
        é: 'e',
        ê: 'e',
        ë: 'e',
        ì: 'i',
        í: 'i',
        î: 'i',
        ï: 'i',
        ð: 'd',
        ñ: 'n',
        ò: 'o',
        ó: 'o',
        ô: 'o',
        ö: 'o',
        õ: 'o',
        ø: 'o',
        œ: 'oe',
        ß: 'ss',
        ù: 'u',
        ú: 'u',
        û: 'u',
        ü: 'u',
        ý: 'y',
        þ: 'th',
        ÿ: 'y',
        ẞ: 'SS',
    };

    const normalizedStr = str
        ?.toLowerCase() // convert to lowercase
        ?.replace(/\s+/g, '-') // replace spaces with hyphens
        ?.replace("'", '-') // replace single quote with hyphens
        ?.replace(',', '') // remove commas
        ?.replace(/[^\\u0000-\\u007E]/g, (char) => {
            // replace accented characters with unaccented equivalents
            return accentsMap[char] || char;
        })
        ?.replace('&-', '');

    return normalizedStr || '/';
}
