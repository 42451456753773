import { ICartContextInternalAPI } from '../CartContext';
import { useCallback } from 'react';
import { useCartContext } from '../useCartContext';
import { useSetPaymentMethodOnCartMutation } from '../graphql';

export const useSetPaymentMethodShipDateOnCartAction = () => {
    const [
        setPaymentMethodOnCartMutation,
        setPaymentMethodOnCartMutationResult,
    ] = useSetPaymentMethodOnCartMutation();

    const { getCartId } = useCartContext() as ICartContextInternalAPI;

    const setPaymentMethod = useCallback(
        async (code: string, shipDate?: string) => {
            const cartId = await getCartId();

            const resp = await setPaymentMethodOnCartMutation({
                variables: {
                    cart_id: cartId,
                    payment_method: code,
                    ship_date: shipDate,
                },
            });

            return resp;
        },
        [getCartId, setPaymentMethodOnCartMutation]
    );

    const setPaymentMethodByCardId = async (
        cart_id: string,
        code: string,
        shipDate?: string
    ) => {
        const resp = await setPaymentMethodOnCartMutation({
            variables: {
                cart_id: cart_id,
                payment_method: code,
                ship_date: shipDate,
            },
        });

        return resp;
    };

    return {
        setPaymentMethod,
        setPaymentMethodByCardId,
        setPaymentMethodOnCartMutationResult,
    } as const;
};
