import {
    CustomerAccountContext,
    ICustomerAccountContext,
} from './CustomerAccountContext';

import { useContext } from 'react';

const useCustomerAccount = (): ICustomerAccountContext => {
    const context = useContext(CustomerAccountContext);
    if (typeof context === 'undefined')
        throw Error('CustomerAccountContext is required');
    return context;
};

export { useCustomerAccount };
