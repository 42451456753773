import {
    IProductInventory,
    IProductPrice,
} from '../../../engine/PricesAndInventoriesCache';
import { useEffect, useMemo, useState } from 'react';

import { IStoreContextInternalAPI } from '../StoreContext';
import { useStore } from '../useStore';

export function useProductPrice(
    sku: string | undefined | null,
    options?: { maxAge?: number }
) {
    const { pricesAndInventoriesCache, currentStore } =
        useStore() as IStoreContextInternalAPI;
    const productResource = useMemo(() => {
        if (!currentStore) throw new Error('Current Store is not yet defined');

        if (!sku) return undefined;

        return pricesAndInventoriesCache.getProductResource(sku);
    }, [pricesAndInventoriesCache, currentStore, sku]);

    const [price, setPrice] = useState<IProductPrice | undefined>(() => {
        if (!productResource) return undefined;
        productResource.getPriceImmediate();
    });

    useEffect(() => {
        if (!productResource) return undefined;

        productResource?.refresh();
        setPrice(productResource.getPriceImmediate());

        productResource.subscribePrice(setPrice, { maxAge: options?.maxAge });
        setPrice(productResource.getPriceImmediate());

        return () => {
            productResource.unsubscribePrice(setPrice);
        };
    }, [productResource, options]);

    return [price] as const;
}

export function useProductsPricesAndCode(skus: string[]) {
    const { pricesAndInventoriesCache, currentStore } =
        useStore() as IStoreContextInternalAPI;
    const productsResources = useMemo(() => {
        if (!currentStore) throw new Error('Current Store is not yet defined');

        return skus.map((sku) => {
            return {
                price: pricesAndInventoriesCache.getProductResource(sku),
                sku: sku,
            };
        });
    }, [pricesAndInventoriesCache, currentStore, skus]);

    const [prices, setPrices] = useState<(any | undefined)[]>(() =>
        productsResources.map((productResource) => {
            return {
                price: productResource?.price?.getPriceImmediate(),
                sku: productResource.sku,
            };
        })
    );
    return [prices] as const;
}

export function useProductInventory(
    sku: string,
    options?: { maxAge?: number }
) {
    const { pricesAndInventoriesCache, currentStore } =
        useStore() as IStoreContextInternalAPI;
    const productResource = useMemo(() => {
        if (!currentStore) throw new Error('Current Store is not yet defined');

        return pricesAndInventoriesCache.getProductResource(sku);
    }, [pricesAndInventoriesCache, currentStore, sku]);

    const [inventory, setInventory] = useState<IProductInventory | undefined>(
        () => productResource.getInventoryImmediate()
    );

    useEffect(() => {
        setInventory(productResource.getInventoryImmediate());
        productResource.subscribeInventory(setInventory, {
            maxAge: options?.maxAge,
        });
        return () => {
            productResource.unsubscribeInventory(setInventory);
        };
    }, [productResource, options, setInventory]);

    return [inventory] as const;
}
