import { useBoutikService } from '../../boutikService';
import { useUpdateCustomerInventoryEmailMutation } from '../graphql';

export interface IUpdateCustomerInventoryEmailInput {
    inventory_email?: string;
}

export const useUpdateCustomerInventoryEmailAction = () => {
    const { assertAuthenticated } = useBoutikService();
    const [
        updateCustomerInventoryEmailMutation,
        updateCustomerInventoryEmailMutationResponse,
    ] = useUpdateCustomerInventoryEmailMutation();

    const updateCustomerIventoryEmail = async (
        customer: IUpdateCustomerInventoryEmailInput
    ) => {
        assertAuthenticated();

        await updateCustomerInventoryEmailMutation({
            variables: customer,
        });
    };

    return [
        updateCustomerIventoryEmail,
        updateCustomerInventoryEmailMutationResponse,
    ] as const;
};
