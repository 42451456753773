import { ICartContextInternalAPI } from '../CartContext';
import { invalidateCartCheckoutDetails } from './apolloCacheUtils';
import { useAddBundleProductsToCartMutation } from '../graphql';
import { useCallback } from 'react';
import { useCartContext } from '../useCartContext';

export type BundleOption = {
    id: number;
    quantity: number;
    value: Array<string>;
};

export const useAddBundleProductsToCartAction = () => {
    const [
        addBundleProductsToCartMutation,
        addBundleProductsToCartMutationResult,
    ] = useAddBundleProductsToCartMutation();

    const { getCartId } = useCartContext() as ICartContextInternalAPI;

    const addBundleProductToCart = useCallback(
        async (
            sku: string,
            quantity: number,
            bundle_options: BundleOption[]
        ) => {
            const cartId = await getCartId();

            if (!cartId) {
                console.error(
                    'useAddBundleProductsToCartAction: No cartId found'
                );
                return null;
            }

            const resp = await addBundleProductsToCartMutation({
                variables: {
                    cartId,
                    quantity,
                    sku,
                    bundle_options,
                },
                update: invalidateCartCheckoutDetails,
            });

            return resp;
        },
        [addBundleProductsToCartMutation, getCartId]
    );

    return [
        addBundleProductToCart,
        addBundleProductsToCartMutationResult,
    ] as const;
};
