import {
    PreloadAfterCustomerSignInDocument,
    PreloadAfterCustomerSignInQuery,
    PreloadAfterCustomerSignInQueryVariables,
} from '../graphql/preloadAfterCustomerSignInQuery.generated';

import { IBoutikServiceContextInternalAPI } from '../../boutikService/BoutikServiceContext';
import { invalidateCartCheckoutDetails } from '../../cart/actions/apolloCacheUtils';
import { useApolloClient } from '@apollo/client';
import { useBoutikService } from '../../boutikService';
import { useCallback } from 'react';
import { useSignInMutation } from '../graphql';

export const useSignInAction = () => {
    const { boutikService, assertNotAuthenticated, setAccessToken } =
        useBoutikService() as IBoutikServiceContextInternalAPI;
    const [signInMutation, signInMutationResponse] = useSignInMutation();
    const apolloClient = useApolloClient();

    const signIn = useCallback(
        async (email: string, password: string): Promise<void> => {
            assertNotAuthenticated();

            const response = await signInMutation({
                variables: { email, password },
                fetchPolicy: 'no-cache',
            });

            if (response.data?.generateCustomerToken?.token) {
                // FIXME: Find a better strategy here...
                boutikService.accessToken =
                    response.data.generateCustomerToken.token;

                await apolloClient.query<
                    PreloadAfterCustomerSignInQuery,
                    PreloadAfterCustomerSignInQueryVariables
                >({
                    query: PreloadAfterCustomerSignInDocument,
                    fetchPolicy: 'network-only',
                });

                localStorage.setItem('isProEnabled', 'false');
                localStorage.setItem('proDiscount', 'false');

                invalidateCartCheckoutDetails(apolloClient.cache);

                setAccessToken(response.data.generateCustomerToken.token, 3600);
            }
        },
        [
            signInMutation,
            boutikService,
            setAccessToken,
            assertNotAuthenticated,
            apolloClient,
        ]
    );

    return [signIn, signInMutationResponse] as const;
};
