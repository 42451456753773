// This file was automatically generated by graphql-code-generator
// Changes to this file will be lost


import * as Types from '../../../engine/apis/magento2/types.generated.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCustomerInventoryEmailMutationVariables = Types.Exact<{
  inventory_email?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateCustomerInventoryEmailMutation = { __typename?: 'Mutation', updateCustomerInventoryEmail?: { __typename?: 'UpdateCustomerInventoryEmailOutput', customer: { __typename?: 'Customer', firstname?: string | null, lastname?: string | null } } | null };


export const UpdateCustomerInventoryEmailDocument = gql`
    mutation updateCustomerInventoryEmail($inventory_email: String) {
  updateCustomerInventoryEmail(input: {inventory_email: $inventory_email}) {
    customer {
      firstname
      lastname
    }
  }
}
    `;
export type UpdateCustomerInventoryEmailMutationFn = Apollo.MutationFunction<UpdateCustomerInventoryEmailMutation, UpdateCustomerInventoryEmailMutationVariables>;

/**
 * __useUpdateCustomerInventoryEmailMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerInventoryEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerInventoryEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerInventoryEmailMutation, { data, loading, error }] = useUpdateCustomerInventoryEmailMutation({
 *   variables: {
 *      inventory_email: // value for 'inventory_email'
 *   },
 * });
 */
export function useUpdateCustomerInventoryEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerInventoryEmailMutation, UpdateCustomerInventoryEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerInventoryEmailMutation, UpdateCustomerInventoryEmailMutationVariables>(UpdateCustomerInventoryEmailDocument, options);
      }
export type UpdateCustomerInventoryEmailMutationHookResult = ReturnType<typeof useUpdateCustomerInventoryEmailMutation>;
export type UpdateCustomerInventoryEmailMutationResult = Apollo.MutationResult<UpdateCustomerInventoryEmailMutation>;
export type UpdateCustomerInventoryEmailMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerInventoryEmailMutation, UpdateCustomerInventoryEmailMutationVariables>;