import React from 'react';

export const cookieConsentConfig = {
    containerId: process.env.GATSBY_GTM_CONTAINER,
    descriptionSmall: {
        en_CA: 'With your consent, we use cookies to improve your browsing experience, deliver personalized ads or content, and analyze visits to this website. By clicking on “Accept all”, you agree [to our privacy and cookies policy.](/ca/en/privacy-policy/)',
        en_US: 'With your consent, we use cookies to improve your browsing experience, deliver personalized ads or content, and analyze visits to this website. By clicking on “Accept all”, you agree [to our privacy and cookies policy.](/ca/en/privacy-policy/)',
        fr_CA: 'Avec votre consentement, nous utilisons des cookies pour améliorer votre expérience de navigation, diffuser des publicités ou des contenus personnalisés et analyser les visites à ce site Web. En cliquant sur « Tout accepter », vous consentez [à notre politique de confidentialité et cookies.](/ca/fr/politique-de-confidentialite/)',
    },
};
