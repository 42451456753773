import React, { createContext } from 'react';

export interface ICustomerAccountContext {
    currentUser: ICustomerAccount | undefined;
    customerId: string;
    isIdentified: boolean;
    setIsProEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ICustomerAccountContextInternalAPI
    extends ICustomerAccountContext {
    // This is an internal API. It should only be called from the signout action.
    // Destroys retained informations about the customer account profile. After
    // that, currentUser will be null and isIdentified will return false.
    clearCustomerProfile: () => void;
}

export interface ICustomerAccount {
    firstname: string;
    lastname: string;
    email: string;
    isSubscribed: boolean;
    isPro: boolean;
    isProEnabled: boolean;
    extra_attributes: string[];
    proDiscount: number;
    store: string;
    lang: string;
    inventoryEmail: string | null;
}

export const CustomerAccountContext = createContext<ICustomerAccountContext>(
    // We don't really want a default value, but createContext requires one,
    // and TypeScript expect it to have correct type.
    undefined as unknown as ICustomerAccountContext
);
