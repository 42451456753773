import {
    SetBillingAddressOnCartMutationVariables,
    useSetBillingAddressIdOnCartMutation,
    useSetBillingAddressOnCartMutation,
} from '../graphql';

import { ICartContextInternalAPI } from '../CartContext';
import { useCallback } from 'react';
import { useCartContext } from '../useCartContext';

export const useSetBillingAddressOnCartAction = () => {
    const { getCartId } = useCartContext() as ICartContextInternalAPI;

    const [
        setBillingAddressIdOnCartMutation,
        setBillingAddressIdOnCartMutationResult,
    ] = useSetBillingAddressIdOnCartMutation();

    const setBillingAddressId = async (customer_address_id: number) => {
        const cartId = await getCartId();

        if (!cartId) return;

        const resp = await setBillingAddressIdOnCartMutation({
            variables: {
                customer_address_id,
                cart_id: cartId,
            },
        });

        return resp;
    };

    const setBillingAddressIdByCartId = async (
        customer_address_id: number,
        cart_id: string
    ) => {
        const resp = await setBillingAddressIdOnCartMutation({
            variables: {
                customer_address_id,
                cart_id: cart_id,
            },
        });

        return resp;
    };

    const [
        setBillingAddressOnCartMutation,
        setBillingAddressOnCartMutationResult,
    ] = useSetBillingAddressOnCartMutation();

    const setBillingAddress = async (
        address: SetBillingAddressOnCartMutationVariables
    ) => {
        const cartId = await getCartId();

        if (!cartId) return;

        const resp = await setBillingAddressOnCartMutation({
            variables: {
                ...address,
                cart_id: cartId,
            },
        });

        return resp;
    };

    const setBillingAddressByCartId = async (
        address: SetBillingAddressOnCartMutationVariables,
        cart_id: string
    ) => {
        const resp = await setBillingAddressOnCartMutation({
            variables: {
                ...address,
                cart_id: cart_id,
            },
        });

        return resp;
    };

    return {
        setBillingAddress,
        setBillingAddressIdByCartId,
        setBillingAddressByCartId,
        setBillingAddressOnCartMutationResult,
        setBillingAddressId: setBillingAddressId,
        setBillingAddressIdOnCartMutationResult,
    };
};
