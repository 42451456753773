import { IBoutikServiceContextInternalAPI } from '../../boutikService/BoutikServiceContext';
import { ICustomerAccountContextInternalAPI } from '../CustomerAccountContext';
import { navigate } from 'gatsby';
import { useApolloClient } from '@apollo/client';
import { useBoutikService } from '../../boutikService';
import { useCallback } from 'react';
import { useCustomerAccount } from '../';
import { useSignOutMutation } from '../graphql';

export const useSignOutAction = () => {
    const { isAuthenticated, setAccessToken } =
        useBoutikService() as IBoutikServiceContextInternalAPI;
    const { clearCustomerProfile } =
        useCustomerAccount() as ICustomerAccountContextInternalAPI;

    const [signOutMutation, signOutMutationResponse] = useSignOutMutation();

    const signOut = useCallback(async (): Promise<void> => {
        try {
            if (isAuthenticated)
                await signOutMutation({ fetchPolicy: 'no-cache' });

            navigate('/');
            localStorage.setItem('isProEnabled', 'false');
            localStorage.setItem('proDiscount', 'false');
        } catch (error) {
            // Intentionnaly ignore exceptions. This is signout. Nobody really
            // care if the server rejected the signout request. Just destroy
            // local data related to the session, and get over it.
        } finally {
            setAccessToken(undefined);
            clearCustomerProfile();
        }
    }, [
        signOutMutation,
        setAccessToken,
        isAuthenticated,
        clearCustomerProfile,
    ]);

    return [signOut, signOutMutationResponse] as const;
};
