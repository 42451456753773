import { MagentoReviewsRepository } from '../interfaces/repositories/magentoReviewsRepository';
import { HttpService } from '../interfaces/services/httpService';
import { HttpResponse } from '../models/httpResponse';
import { ProductReviews } from '../models/productReviews';

export class MagentoReviewsHttpRepository implements MagentoReviewsRepository {
    private httpService: HttpService;

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    async getReviewsBySku(sku: string): Promise<ProductReviews | null> {
        return await this.httpService
            .get(`productReviews/${sku}`)
            .then((response: HttpResponse) => {
                return response.status == 200
                    ? (response.data[0] as ProductReviews)
                    : null;
            })
            .catch((error) => {
                throw error;
            });
    }
}
