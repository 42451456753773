import {
    CustomerAccountContext,
    ICustomerAccount,
} from './CustomerAccountContext';
import React, { useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { useBoutikService } from '../boutikService';
import { useGetUserProfileQuery } from './graphql';
import { useLocalStorage } from '../../utils/useLocalStorage';

export interface ICustomerAccountProviderProps {
    children: React.ReactNode;
}

export const CustomerAccountProvider: React.FC<
    ICustomerAccountProviderProps
> = ({ children }) => {
    const apolloClient = useApolloClient();

    const { isAuthenticated, assertNotAuthenticated } = useBoutikService();
    const [customerProfile, setCustomerProfile] =
        useLocalStorage<ICustomerAccount>('CustomerAccount:customerProfile');
    const { data: userProfileData } = useGetUserProfileQuery({
        fetchPolicy: 'cache-first',
        skip: !isAuthenticated,
    });
    const [store, setStore] = useState<string>('none');
    const [language, setLanguage] = useState<string>('');
    const [customerId, setCustomerId] = useState<string>('');
    const [proDiscount, setProDiscount] = useState<number>(0);
    const [isProEnabled, setIsProEnabled] = useState<boolean>(false);

    // Garde les states lorsq'on refresh la page
    useEffect(() => {
        const storedIsProEnabled = localStorage.getItem('isProEnabled');
        const storedProDiscount = parseInt(
            localStorage.getItem('proDiscount') ?? '0'
        );
        setIsProEnabled(
            storedIsProEnabled ? JSON.parse(storedIsProEnabled) : false
        );
        setProDiscount(storedProDiscount ? storedProDiscount : 0);
    }, []);

    // update le localStorage lorsque la valeur change (dans Header ou Sidenav)
    useEffect(() => {
        localStorage.setItem('isProEnabled', JSON.stringify(isProEnabled));
        localStorage.setItem('proDiscount', proDiscount.toString());
    }, [isProEnabled, proDiscount]);

    useGetMagentoCurrentCustomerData().then((data) => {
        if (data) {
            // group 6 is 15% discount,
            // group 7 is 20% discount
            // default has no discount
            const createdInArray = data.created_in.split(' ');
            const country = createdInArray[2];
            const language = createdInArray[1].toLowerCase();

            setCustomerId(data.id);
            setStore(country === 'US' ? country : 'CA');
            setLanguage(
                language.includes('fr') ? `fr_${country}` : `en_${country}`
            );

            setProDiscount(
                data?.group_id === 6 ? 15 : data?.group_id === 7 ? 20 : 0
            );
        }
    });

    useEffect(() => {
        if (userProfileData?.customer) {
            const extra_attributes =
                userProfileData?.customer?.extra_attributes;
            let jsonBrands: string[] = [];

            if (extra_attributes) {
                const brands: { brands: string[] } =
                    JSON.parse(extra_attributes);

                const brandsObject = brands.brands[0];

                jsonBrands = brandsObject.split(';').map((brand: string) => {
                    return brand;
                });
            }

            const newCustomerProfile = {
                email: userProfileData.customer.email || '',
                inventoryEmail: userProfileData.customer.inventoryEmail || null,
                firstname: userProfileData.customer.firstname || '',
                lastname: userProfileData.customer.lastname || '',
                isSubscribed: userProfileData.customer.isSubscribed || false,
                isPro: userProfileData?.customer?.extra_attributes
                    ? true
                    : false,
                isProEnabled: isProEnabled,
                extra_attributes: jsonBrands,
                proDiscount,
                store: store,
                lang: language,
            };

            const oldCustomerProfile = {
                email: customerProfile?.email || '',
                inventoryEmail: customerProfile?.inventoryEmail || null,
                firstname: customerProfile?.firstname || '',
                lastname: customerProfile?.lastname || '',
                isSubscribed: customerProfile?.isSubscribed || false,
                isPro: customerProfile?.extra_attributes ? true : false,
                isProEnabled: customerProfile?.isProEnabled,
                extra_attributes: jsonBrands,
                proDiscount,
                store: store,
                lang: language,
            };

            if (
                JSON.stringify(oldCustomerProfile) !==
                JSON.stringify(newCustomerProfile)
            ) {
                setCustomerProfile(newCustomerProfile);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        userProfileData,
        isProEnabled,
        proDiscount,
        store,
        language,
        customerId,
    ]);

    const clearCustomerProfile = () => {
        assertNotAuthenticated();
        setCustomerProfile(undefined);
        apolloClient.cache.reset();
    };

    // Implicitely destroy customer profile and cache when user gets deauthenticated.
    // Note that clearCustomerProfile() is explicitely called in logout scenarios;
    // however, this effect is still required to handle the case where the user gets
    // deauthentified because his auth token expired.
    useEffect(() => {
        if (!isAuthenticated && customerProfile) {
            clearCustomerProfile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, customerProfile]);

    const contextValue = {
        currentUser: customerProfile,
        customerId: customerId,
        isIdentified: customerProfile !== undefined,
        clearCustomerProfile,
        setIsProEnabled: setIsProEnabled,
    };

    return (
        <CustomerAccountContext.Provider value={contextValue}>
            {children}
        </CustomerAccountContext.Provider>
    );
};

async function useGetMagentoCurrentCustomerData() {
    const { boutikService, isAuthenticated } = useBoutikService();

    // BoutikService might not be ready yet or customer might have been deauthenticated.
    if (
        !boutikService.storeViewCode ||
        !boutikService.accessToken ||
        !isAuthenticated
    )
        return null;

    const url = `/rest/${boutikService.storeViewCode}/V1/customers/me`;

    const response = await fetch(url, {
        method: 'get',
        headers: {
            Authorization: `Bearer ${boutikService.accessToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (response.status !== 200) {
        console.error(`Magento ${url} call failed`, response);
        throw new Error(`Magento ${url} call failed`);
    }

    const data = await response.json();

    return data;
}
