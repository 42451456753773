import { MagentoInventoryRepository } from '../interfaces/repositories/magentoInventoryRepository';
import { InventoryService } from '../interfaces/services/inventoryService';
import { ProInventory } from '../models/proInventory';
import { ProductProInventory } from '../models/productProInventory';

export class InventoryServiceImpl implements InventoryService {
    private repository: MagentoInventoryRepository;

    constructor(repository: MagentoInventoryRepository) {
        this.repository = repository;
    }

    async getProductProInventoryByEmail(
        storeCode: string,
        email: string,
        setProgress: (progress: number) => void
    ): Promise<ProductProInventory[]> {
        const inventory: ProductProInventory[] = [];
        let fragmentInventory = [];
        let pageCount = 1;
        do {
            setProgress(pageCount);
            fragmentInventory = await this.repository
                .getProInventoryByEmail(email, storeCode, pageCount)
                .then((proInventory: ProInventory) =>
                    proInventory ? proInventory.productInventory : []
                )
                .catch(() => []);
            inventory.push(...fragmentInventory);
            pageCount++;
        } while (fragmentInventory.length > 0);
        return inventory;
    }
}
