import { ICartContextInternalAPI } from '../CartContext';
import { invalidateCartCheckoutDetails } from './apolloCacheUtils';
import { useCartContext } from '../useCartContext';
import { useUpdateCartItemsMutation } from '../graphql';

export const useUpdateCartItemsAction = () => {
    const [updateCartItemsMutation, updateCartItemsMutationResult] =
        useUpdateCartItemsMutation();

    const { getCartId } = useCartContext() as ICartContextInternalAPI;

    const updateCartItems = async (cart_item_id: number, quantity: number) => {
        const cartId = await getCartId();

        const resp = await updateCartItemsMutation({
            variables: {
                cart_id: cartId as string,
                cart_item_id: cart_item_id,
                quantity: quantity,
            },
            update: invalidateCartCheckoutDetails,
        });

        return resp;
    };

    return [updateCartItems, updateCartItemsMutationResult] as const;
};
