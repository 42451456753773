import { ICartContextInternalAPI } from '../CartContext';
import { invalidateCartCheckoutDetails } from './apolloCacheUtils';
import { useCartContext } from '../useCartContext';
import { useRemoveItemFromCartMutation } from '../graphql';

export const useRemoveItemFromCartAction = () => {
    const [removeItemFromCartMutation, removeItemFromCartMutationResult] =
        useRemoveItemFromCartMutation();

    const { getCartId } = useCartContext() as ICartContextInternalAPI;

    const removeCartItem = async (cart_item_id: number) => {
        const cartId = await getCartId();

        const resp = await removeItemFromCartMutation({
            variables: {
                cart_id: cartId as string,
                cart_item_id: cart_item_id,
            },
            update: invalidateCartCheckoutDetails,
        });

        return resp;
    };

    return [removeCartItem, removeItemFromCartMutationResult] as const;
};
