import { MagentoInventoryRepository } from '../interfaces/repositories/magentoInventoryRepository';
import { HttpService } from '../interfaces/services/httpService';
import { HttpResponse } from '../models/httpResponse';
import { ProInventory } from '../models/proInventory';

export class MagentoInventoryHttpRepository
    implements MagentoInventoryRepository
{
    private httpService: HttpService;

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    async getProInventoryByEmail(
        email: string,
        storeCode: string,
        page: number
    ): Promise<ProInventory> {
        return await this.httpService
            .get(`pro-inventory/${storeCode}/${email}/${page}`)
            .then((response: HttpResponse) => response.data as ProInventory)
            .catch((error) => {
                throw error;
            });
    }
}
