import { ICartContextInternalAPI } from '../CartContext';
import { useCallback } from 'react';
import { useCartContext } from '../useCartContext';
import { useSetGuestEmailOnCartMutation } from '../graphql';

export const useSetGuestEmailOnCartAction = () => {
    const { getCartId } = useCartContext() as ICartContextInternalAPI;

    const [setGuestEmailOnCartMutation, setGuestEmailOnCartMutationResult] =
        useSetGuestEmailOnCartMutation();

    const setGuestEmailOnCart = async (guest_email: string) => {
        const cartId = await getCartId();

        const resp = await setGuestEmailOnCartMutation({
            variables: {
                email: guest_email,
                cart_id: cartId,
            },
        });
        return resp;
    };

    const setGuestEmailOnCartByCartId = async (
        guest_email: string,
        cart_id: number
    ) => {
        const resp = await setGuestEmailOnCartMutation({
            variables: {
                email: guest_email,
                cart_id: cart_id,
            },
        });
        return resp;
    };

    return {
        setGuestEmailOnCart,
        setGuestEmailOnCartMutationResult,
        setGuestEmailOnCartByCartId,
    };
};
